import React from "react";
import Dashboard from "../components/Dashboard";
import { useNavigate } from "react-router-dom";
import { isTokenValid, healthCheck } from "../ApiService";

function Home() {
    const nav = useNavigate();

    React.useEffect(() => {
        const token = localStorage.getItem("token");
        if (!token) {
            return nav("/login");
        }
        isTokenValid(token).then((res) => {
            if (!res) {
                localStorage.removeItem("token");
                return nav("/login");
            }
        });
    }, []);

    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        window.location.reload();
    };

    return (
        <div className="flex fullPage x-center flex-direction-column y-center">
            <div className="flex space-between">
                <div>
                    <h2> Unlucky Studio - Expenses</h2>
                </div>
            </div>
            <Dashboard handleLogout={handleLogout} />
        </div>
    );
}

export default Home;
