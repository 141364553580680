import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BsDatabaseAdd } from "react-icons/bs";
import Modal from "./Modal";
import { fetchExpenses } from "../ApiService";
import ExpensesList from "./ExpensesList";

function Dashboard({ handleLogout }) {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [expenses, setExpenses] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [token, setToken] = useState("");
    const nav = useNavigate();

    const checkIfUserIsLoggedIn = async () => {
        const user = JSON.parse(localStorage.getItem("user"));
        const tokenFromStorage = localStorage.getItem("token");

        if (!tokenFromStorage || !user) {
            localStorage.removeItem("token");
            localStorage.removeItem("user");
            return nav("/login");
        }
        setUsername(user.username);
        setToken(tokenFromStorage);

        return tokenFromStorage;
    };

    const fetchExpensesFromApi = async (token) => {
        const res = await fetchExpenses(token);
        if (res) {
            if (res.error) {
                return;
            } else {
                if (res.expenses) {
                    setExpenses(res.expenses);
                    // check if theres expense off due date
                    res.expenses.forEach((expense) => {
                        const dueDate = new Date(expense.dueDate);
                        const today = new Date();
                        if (dueDate < today) {
                            expense.isExpired = true;
                        } else {
                            expense.isExpired = false;
                        }
                    });
                    setTimeout(() => {
                        setIsLoaded(true);
                    }, 1000);
                }
            }
        }
    };

    React.useEffect(() => {
        const initializeDashboard = async () => {
            const token = await checkIfUserIsLoggedIn();
            if (token) {
                fetchExpensesFromApi(token);
            }
        };

        initializeDashboard();
    }, []);

    const refreshExpenses = () => {
        setIsLoaded(false);
        fetchExpensesFromApi(token);
    };

    const handleOpenModal = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="flex flex-direction-row center buttons w-full">
                <div className="flex flex-direction-row space-between w-100 y-center">
                    <button className="button" onClick={handleOpenModal}>
                        <BsDatabaseAdd className="icon" />
                        Add Expense
                    </button>
                    <div className="flex flex-direction-row y-center">
                        <p className="utente"> {username} </p>
                        <button onClick={handleLogout} className="button small">
                            LOGOUT
                        </button>
                    </div>
                </div>
                {isModalOpen && (
                    <Modal
                        fetchExpenses={fetchExpenses}
                        onClose={handleCloseModal}
                        isModalOpen={isModalOpen}
                        onRefreshExpenses={refreshExpenses}
                    />
                )}
            </div>
            <ExpensesList
                user={username}
                expenses={expenses}
                isLoaded={isLoaded}
                token={token}
                onRefreshExpenses={refreshExpenses}
            />
        </>
    );
}

export default Dashboard;
