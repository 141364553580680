import React from "react";
import { useNavigate } from "react-router-dom";
import { loginFunction } from "../ApiService";
import { useEffect } from "react";

function Login() {
    const navigate = useNavigate();
    const [wrongCredentials, setWrongCredentials] = React.useState(false);

    useEffect(() => {
        let token = localStorage.getItem("token");
        if (token) {
            navigate(`/`);
        }
    }, []);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        let { username, password } = document.forms[0];
        const user = {
            username: username.value,
            password: password.value,
        };
        loginFunction(user).then((res) => {
            if (res) {
                if (res.error) {
                    setWrongCredentials(true);
                    username.value = "";
                    password.value = "";
                    return;
                } else {
                    if (res.token) {
                        setWrongCredentials(false);
                        localStorage.setItem("token", res.token);
                        localStorage.setItem("user", JSON.stringify(res.user));
                        navigate(`/`);
                    }
                }
            }
        });
    };

    return (
        <div className="flex fullPage x-center flex-direction-column y-center">
            <div className="flex flex-direction-column center fullPage">
                <form
                    onSubmit={handleLoginSubmit}
                    className="flex flex-direction-column gap-15 w-100 x-center y-center loginForm"
                >
                    <h3> LOGIN </h3>
                    <input
                        className="input"
                        type="text"
                        id="username"
                        name="username"
                        placeholder="Username..."
                        required
                    />
                    <input
                        className="input"
                        type="password"
                        id="password"
                        name="password"
                        placeholder="Password..."
                        required
                    />
                    {wrongCredentials && (
                        <p className="error">Wrong credentials !</p>
                    )}
                    <hr></hr>
                    <input className="button" type="submit" value="Login" />
                    <p className="m-15">
                        Don't have an account? <a href="/register">Register</a>
                    </p>
                </form>
            </div>
        </div>
    );
}

export default Login;
