import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Nopage from "./pages/Nopage";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { healthCheck } from "./ApiService";
import React from "react";

function App() {
    const [isServerDown, setIsServerDown] = React.useState(false);

    React.useEffect(() => {
        healthCheck().then((res) => {
            if (!res) {
                setIsServerDown(true);
            } else {
                setIsServerDown(false);
            }
        });
    }, []);

    if (isServerDown) {
        return (
            <div className="flex fullPage x-center flex-direction-column y-center">
                <div className="flex space-between">
                    <div>
                        <h2> Unlucky Studio - Expenses </h2>
                    </div>
                </div>
                <div className="flex flex-direction-column center fullPage">
                    <h3> Server is down, lol </h3>
                    <p> Please try again later </p>
                </div>
            </div>
        );
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/register" element={<Register />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<Nopage />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
